html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  color: #4a4a4a;
  overflow: hidden;
  font-family: "Montserrat";
}

#root {
  height: calc(100% - 80px);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

textarea,
input,
button,
select {
  font-family: inherit;
}

@font-face {
  font-family: "BalletHarmony";
  src: url("./../../fonts/ballet-harmony.ttf") format("truetype");
}
